import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 533.000000 567.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,567.000000) scale(0.100000,-0.100000)">


<path d="M2920 5654 c-175 -25 -328 -64 -480 -120 -130 -48 -149 -57 -144 -66
3 -4 -1 -5 -9 -2 -8 3 -20 -2 -27 -11 -7 -8 -19 -15 -27 -15 -18 0 -85 -31
-80 -37 3 -2 38 3 78 11 249 50 530 68 734 46 142 -16 312 -54 382 -86 17 -7
35 -14 39 -14 22 0 227 -110 244 -131 3 -3 16 -12 29 -19 44 -23 191 -163 263
-250 46 -56 118 -156 118 -164 0 -3 12 -22 26 -43 27 -38 103 -190 138 -273
10 -25 27 -63 36 -85 20 -44 89 -253 116 -351 l18 -61 195 -7 c108 -3 265 -9
349 -12 l152 -7 0 41 c0 43 -8 85 -47 257 -90 398 -296 747 -583 986 -36 30
-67 57 -70 60 -23 26 -261 168 -335 199 -27 12 -59 25 -70 30 -68 29 -97 40
-160 60 -86 27 -179 46 -310 65 -127 18 -447 18 -575 -1z m169 -18 c-2 -3 -51
-10 -107 -16 -57 -6 -142 -19 -188 -29 -46 -10 -84 -15 -84 -11 0 8 12 11 115
30 97 18 274 35 264 26z m284 -3 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0
35 -2 23 -4z m-246 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m360 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-800 -50 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-67 -19 c-46 -18 -74 -18
-35 0 17 7 39 13 50 13 13 0 7 -5 -15 -13z m-75 -25 c-3 -5 -13 -10 -21 -10
-8 0 -12 5 -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z m-70 -22 c-16 -5 -39 -13
-50 -18 -11 -5 -31 -11 -44 -14 -44 -9 -6 15 45 29 57 15 93 18 49 3z m-125
-32 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M2632 5289 c-104 -9 -203 -28 -342 -65 -131 -35 -130 -34 -130 -43 0
-11 36 -6 86 12 22 8 67 20 100 27 32 6 66 14 74 17 8 3 29 7 45 9 17 1 55 5
85 9 30 3 81 6 114 5 32 0 67 5 78 11 18 10 85 3 75 -8 -3 -2 -63 -9 -134 -14
-70 -5 -139 -11 -153 -14 -14 -3 -41 -8 -60 -11 -47 -8 -170 -41 -204 -54 -15
-5 -31 -8 -36 -5 -5 3 -10 2 -12 -2 -1 -5 -41 -21 -88 -37 -47 -16 -101 -36
-120 -46 -19 -10 -55 -26 -80 -36 -25 -10 -56 -25 -70 -34 -14 -9 -40 -22 -57
-29 -18 -8 -33 -17 -33 -22 0 -5 -6 -9 -13 -9 -7 0 -29 -11 -50 -25 -20 -14
-37 -23 -37 -20 0 3 -6 1 -12 -5 -7 -6 -35 -25 -63 -43 -27 -17 -54 -35 -60
-39 -5 -5 -28 -22 -50 -39 -22 -16 -49 -37 -60 -46 -11 -9 -35 -27 -55 -41
-81 -55 -389 -355 -439 -429 -15 -21 -37 -49 -48 -63 -12 -14 -50 -60 -84
-104 -46 -59 -63 -75 -70 -65 -8 11 -13 9 -29 -10 -11 -13 -20 -27 -20 -33 0
-10 546 -10 616 1 39 6 47 13 119 111 79 105 296 331 370 385 22 15 48 36 57
44 49 46 259 182 353 229 318 160 566 225 865 225 189 0 321 -22 475 -78 113
-41 228 -96 245 -116 3 -3 16 -12 30 -19 14 -7 51 -33 82 -57 31 -23 59 -43
63 -43 5 0 -31 56 -45 70 -3 3 -18 23 -34 45 -17 22 -55 66 -85 99 -228 240
-520 378 -863 406 -127 11 -164 11 -296 -1z m316 -36 c-21 -2 -57 -2 -80 0
-24 2 -7 4 37 4 44 0 63 -2 43 -4z m171 -23 c22 -13 7 -11 -64 6 l-60 14 54
-6 c30 -3 62 -9 70 -14z m48 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z"/>
<path d="M2130 5169 c0 -5 -4 -7 -10 -4 -11 7 -75 -24 -66 -32 3 -3 12 0 21 7
8 7 15 10 15 7 0 -3 14 -1 30 4 28 10 41 29 20 29 -5 0 -10 -5 -10 -11z"/>
<path d="M2026 5133 c-4 -5 -11 -8 -14 -9 -17 -2 -42 -17 -42 -24 0 -10 57 17
65 31 7 11 1 12 -9 2z"/>
<path d="M1900 5075 c-19 -14 -31 -25 -28 -25 13 0 68 33 68 41 0 13 -2 12
-40 -16z"/>
<path d="M1839 5043 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M1765 5010 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1700 4970 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M1650 4945 c-14 -8 -20 -14 -14 -15 5 0 19 7 30 15 24 18 16 19 -16
0z"/>
<path d="M1574 4888 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M1369 4743 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1820 4026 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M1872 4011 c-11 -7 -10 -10 3 -15 23 -9 25 -8 25 9 0 17 -9 19 -28 6z"/>
<path d="M1910 4001 c0 -6 12 -16 28 -22 42 -17 9 -22 -40 -6 -126 43 -330 28
-473 -33 -22 -10 -50 -21 -63 -25 -37 -11 -154 -81 -191 -114 -29 -26 -51 -32
-51 -15 0 3 3 3 8 0 4 -2 12 6 18 19 16 34 -8 27 -51 -15 -42 -41 -43 -58 -9
-99 36 -44 70 -41 125 9 24 22 49 40 55 40 7 0 14 7 18 15 3 8 10 15 15 15 6
0 34 14 63 31 131 77 375 108 518 65 25 -7 66 -20 93 -27 26 -7 47 -17 47 -21
0 -4 6 -8 14 -8 8 0 22 -7 30 -16 9 -8 16 -13 16 -9 0 10 53 -30 114 -85 62
-57 90 -62 130 -25 41 38 38 76 -9 117 -22 18 -49 43 -62 55 -22 21 -168 111
-168 103 0 -1 -18 6 -40 17 -22 11 -53 23 -70 26 -16 3 -38 9 -47 12 -11 4
-18 3 -18 -4z"/>
<path d="M690 3801 c-51 -17 -106 -50 -142 -86 -43 -44 -66 -99 -108 -260 -17
-66 -35 -129 -40 -140 -5 -11 -12 -36 -16 -55 -3 -19 -10 -41 -14 -50 -5 -8
-22 -69 -40 -135 -17 -66 -35 -131 -40 -145 -11 -32 -44 -144 -70 -237 -34
-125 -74 -263 -115 -403 -8 -25 -23 -78 -34 -117 -12 -40 -30 -106 -42 -145
-11 -40 -23 -80 -25 -89 -4 -14 20 -17 198 -22 112 -4 240 -7 285 -7 l81 0 11
43 c5 23 19 79 30 125 28 111 59 235 76 302 8 30 23 92 34 138 12 45 30 119
42 165 11 45 26 107 34 137 8 30 24 94 35 143 12 48 25 87 29 87 3 0 12 -12
18 -27 7 -16 27 -55 46 -88 35 -59 83 -148 129 -235 12 -25 25 -47 28 -50 3
-3 21 -35 40 -73 19 -37 40 -75 45 -85 36 -63 88 -156 117 -212 39 -72 97
-179 155 -284 37 -66 40 -69 98 -88 106 -34 330 -26 382 15 7 5 34 50 61 101
27 50 65 121 86 159 20 37 51 95 70 130 55 102 236 437 277 512 21 39 55 103
76 143 36 69 53 88 53 58 0 -7 15 -60 34 -118 19 -57 41 -129 50 -159 8 -30
24 -84 36 -121 11 -38 29 -98 40 -135 28 -91 44 -145 70 -228 12 -38 28 -95
36 -125 8 -30 19 -64 24 -75 4 -11 16 -47 26 -80 9 -33 21 -64 25 -69 8 -9
539 -29 547 -21 2 1 -10 45 -27 96 -16 52 -39 127 -50 167 -12 39 -30 105 -42
145 -11 39 -26 90 -34 112 -13 38 -30 98 -76 258 -11 39 -26 90 -34 112 -7 22
-23 73 -34 113 -54 185 -64 221 -81 277 -11 33 -28 94 -40 135 -12 41 -25 86
-30 100 -10 31 -53 173 -76 256 -17 60 -19 62 -68 82 -210 84 -339 75 -438
-30 -21 -21 -38 -44 -38 -50 0 -6 -6 -19 -14 -27 -12 -14 -60 -100 -128 -231
-13 -25 -26 -46 -30 -48 -5 -2 -8 -12 -8 -22 0 -10 -7 -20 -15 -24 -16 -6 -21
-26 -6 -26 6 0 22 24 36 53 15 28 32 59 40 67 7 8 25 40 40 70 15 30 35 61 44
68 12 10 12 11 1 6 -8 -5 -3 7 13 25 15 18 27 37 27 42 0 10 36 65 55 84 25
25 75 55 101 60 27 6 26 5 -6 -11 -55 -29 -106 -83 -146 -159 -54 -103 -99
-186 -170 -317 -35 -64 -64 -122 -64 -128 0 -5 -4 -10 -8 -10 -5 0 -16 -17
-25 -37 -9 -21 -21 -46 -27 -55 -19 -34 -30 -53 -61 -113 -17 -33 -34 -62 -38
-65 -12 -10 -31 -50 -31 -65 0 -9 -4 -13 -10 -10 -5 3 -10 2 -10 -4 0 -14 -17
-52 -40 -86 -11 -16 -27 -45 -36 -64 -8 -19 -22 -40 -30 -48 -8 -8 -14 -20
-14 -28 0 -17 -57 -65 -76 -65 -10 0 -13 6 -9 19 4 13 -36 90 -127 247 -72
126 -135 231 -140 232 -4 2 -8 9 -8 15 0 10 -128 237 -139 247 -3 3 -14 21
-23 40 -18 37 -45 83 -96 165 -17 28 -39 67 -48 88 -9 20 -20 37 -24 37 -4 0
-10 8 -13 18 -8 24 -56 103 -76 126 -38 43 -112 68 -211 72 -52 2 -106 0 -120
-5z m30 -35 c-57 -16 -94 -39 -143 -91 -47 -49 -61 -75 -77 -145 -13 -57 -29
-96 -30 -72 0 14 22 89 46 158 30 85 138 161 229 162 11 0 0 -5 -25 -12z m123
7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-379 -372 c-4 -17
-9 -30 -11 -27 -3 2 -2 19 2 36 9 41 17 33 9 -9z m-30 -110 c-21 -72 -29 -74
-14 -5 14 64 19 78 26 71 4 -3 -2 -33 -12 -66z m-33 -114 c-13 -13 -15 1 -4
30 7 17 8 17 11 -1 2 -10 -1 -23 -7 -29z m-14 -39 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-21 -79 c-18 -41 -20 -29 -4 26 6 22 13 33 15 24
2 -8 -3 -31 -11 -50z m-19 -61 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-12 -48 c-4 -12 -9 -19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3
2 -15 -2 -27z m-18 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-10 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-12 -48 c-4
-12 -9 -19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3 2 -15 -2 -27z m-18
-52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-12 -48 c-4 -12 -9
-19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3 2 -15 -2 -27z m-18 -52 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10 -40 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-12 -48 c-4 -12 -9 -19 -12 -17 -3 3 -2
15 2 27 4 12 9 19 12 17 3 -3 2 -15 -2 -27z m-18 -52 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-10 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-12 -48 c-4 -12 -9 -19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12
17 3 -3 2 -15 -2 -27z m-18 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-10 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-12
-48 c-4 -12 -9 -19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3 2 -15 -2
-27z m-34 -107 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z
m-40 -140 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z"/>
<path d="M3825 3784 c-183 -11 -323 -27 -425 -49 -140 -30 -232 -54 -244 -65
-11 -11 23 -6 94 14 30 8 69 17 85 19 17 3 48 9 70 15 34 9 76 14 225 27 14 1
27 3 30 4 3 1 34 4 70 6 36 3 84 7 108 10 25 4 42 2 42 -4 0 -15 -25 -19 -155
-26 -198 -9 -498 -68 -526 -101 -6 -8 -17 -14 -25 -14 -8 0 -11 -8 -9 -20 2
-12 0 -18 -6 -15 -5 4 -7 10 -4 15 3 4 -2 13 -10 20 -8 7 -13 16 -10 20 3 5 1
11 -4 14 -26 17 -23 -20 14 -144 9 -30 25 -88 36 -127 10 -40 28 -106 38 -145
11 -40 27 -98 36 -128 9 -30 25 -86 35 -125 11 -38 29 -104 40 -145 12 -41 26
-95 32 -120 6 -25 12 -47 15 -50 3 -3 309 -7 681 -10 l677 -5 22 -40 c14 -27
22 -59 22 -97 1 -92 25 -73 35 27 2 22 3 3 2 -41 -1 -66 -5 -86 -21 -104 -37
-42 -64 -45 -360 -40 l-280 5 279 5 c173 3 281 9 283 15 2 7 -202 9 -602 7
-389 -1 -605 -6 -605 -12 0 -10 17 -13 50 -11 10 0 11 -1 3 -5 -7 -3 -13 -10
-13 -16 0 -5 -7 -16 -15 -24 -13 -13 -15 -13 -15 -1 0 8 -6 27 -14 41 -25 48
-27 1 -5 -97 25 -115 45 -206 59 -277 6 -30 14 -61 19 -69 8 -12 88 -14 483
-14 469 -1 532 3 737 38 241 41 416 137 481 265 27 53 49 171 50 264 0 80 -25
223 -46 264 -7 15 -14 32 -14 37 0 16 -29 54 -78 101 -52 51 -139 97 -212 113
-30 6 -78 17 -107 23 -29 6 -85 8 -130 5 -43 -4 -253 -9 -468 -13 -520 -8
-794 15 -841 73 -21 25 -26 96 -10 135 28 67 48 73 251 75 396 5 1221 26 1231
32 6 3 35 35 65 71 29 35 60 71 69 80 8 9 38 42 65 75 28 32 57 66 65 75 8 9
37 43 65 75 27 33 53 63 58 66 11 8 -1359 30 -1468 23z m323 -31 c-43 -2 -113
-2 -155 0 -43 1 -8 3 77 3 85 0 120 -2 78 -3z m287 -3 c-28 -12 -143 -12 -150
0 -4 6 26 10 82 9 58 0 81 -3 68 -9z m170 0 c13 -6 -10 -8 -65 -8 -84 1 -84 1
-25 8 33 4 62 7 65 8 3 0 14 -3 25 -8z m128 -17 c-18 -2 -48 -2 -65 0 -18 2
-4 4 32 4 36 0 50 -2 33 -4z m380 0 c-56 -2 -151 -2 -210 0 -59 1 -13 3 102 3
116 0 164 -2 108 -3z m-1836 -555 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m301 -185 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z
m95 -10 c-13 -2 -35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m764 -8 c-23
-12 -79 -15 -295 -14 -147 0 -307 3 -357 7 -64 5 16 9 275 12 201 3 374 6 385
8 15 1 12 -2 -8 -13z m403 8 c0 -5 -31 -7 -69 -5 -38 2 -84 0 -102 -5 -19 -4
-63 -6 -99 -3 l-65 4 70 6 c106 10 265 11 265 3z m80 -13 c13 -9 13 -10 0 -9
-8 0 -24 4 -35 9 l-20 9 20 0 c11 0 27 -4 35 -9z m-1583 -22 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m212 -713 l0 -20 -9 20 c-5 11 -9 29 -9
40 l0 20 9 -20 c5 -11 9 -29 9 -40z m8 -57 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m23 -60 c0 -5 -4 -8 -10 -8 -5 0 -10 10 -10 23 0 18 2 19
10 7 5 -8 10 -18 10 -22z m12 -73 c3 -13 1 -22 -3 -19 -9 5 -13 44 -5 44 2 0
6 -11 8 -25z"/>
<path d="M1728 3733 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1817 3724 c-3 -4 1 -13 11 -20 14 -10 0 -12 -79 -7 -62 4 -115 1
-150 -7 -62 -15 -184 -69 -243 -109 -42 -28 -58 -32 -73 -18 -4 5 -3 7 2 5 6
-1 11 0 13 5 7 21 -15 6 -31 -21 -22 -37 -12 -64 33 -87 34 -18 42 -15 119 40
77 55 123 73 224 87 53 8 97 12 97 8 0 -3 8 -6 18 -6 41 -2 96 -15 132 -34 22
-11 40 -17 40 -13 0 5 6 1 13 -7 7 -8 20 -19 29 -25 9 -5 35 -23 56 -38 38
-27 41 -28 75 -14 26 11 37 23 42 46 3 16 3 37 -2 45 -12 21 -124 106 -140
106 -7 0 -13 4 -13 8 0 5 -22 15 -50 22 -27 7 -50 18 -50 23 0 5 -13 7 -30 5
-16 -2 -31 0 -32 4 -2 5 -7 6 -11 2z"/>
<path d="M1770 3472 c0 -4 5 -13 11 -19 8 -8 -3 -10 -39 -6 -67 6 -155 -18
-228 -61 -62 -38 -80 -44 -68 -23 4 6 -4 2 -18 -11 -24 -22 -25 -25 -11 -53
22 -44 57 -48 96 -11 18 17 52 41 77 53 86 45 231 21 297 -48 27 -28 57 -29
83 -3 27 27 25 64 -5 88 -14 11 -25 26 -25 33 0 7 -3 9 -6 6 -4 -4 -19 2 -34
13 -42 30 -130 58 -130 42z"/>
<path d="M1757 3234 c-3 -3 -3 -12 0 -20 3 -9 -2 -14 -13 -14 -48 0 -111 -33
-135 -71 -13 -22 -32 -39 -41 -39 -43 0 1 -119 52 -142 31 -13 40 -28 17 -28
-16 0 -18 -26 -3 -28 45 -7 127 -6 154 2 32 9 43 31 20 39 -9 3 -3 14 16 33
41 41 55 81 49 137 -3 26 -10 47 -14 47 -5 0 -9 6 -9 14 0 8 -17 28 -37 45
-39 32 -46 35 -56 25z"/>
<path d="M2111 3218 c-14 -23 -14 -23 8 -4 22 20 25 26 13 26 -4 0 -13 -10
-21 -22z"/>
<path d="M1556 3133 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4765 2440 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M257 1746 c-5 -13 11 -74 32 -121 5 -11 21 -47 35 -80 15 -33 34 -73
42 -90 9 -16 28 -52 43 -80 26 -49 100 -165 111 -175 3 -3 19 -23 35 -45 29
-40 212 -226 250 -255 11 -8 34 -26 51 -40 17 -14 40 -30 50 -37 10 -7 42 -28
70 -47 67 -45 148 -79 254 -106 47 -12 101 -26 120 -31 151 -39 707 -39 860 0
19 5 73 19 120 31 47 12 104 31 127 41 24 11 53 24 65 29 30 13 55 30 90 59
30 26 31 39 1 22 -60 -32 -315 -71 -473 -71 -165 0 -378 35 -467 75 -17 8 -36
15 -43 15 -25 0 -210 103 -286 159 -130 96 -225 197 -316 333 -51 77 -152 275
-164 323 -4 16 -13 40 -20 54 -8 14 -14 32 -14 39 0 19 -566 18 -573 -2z m248
-27 c-93 -3 -179 -8 -190 -11 -18 -4 -47 11 -40 22 2 3 92 3 202 0 l198 -4
-170 -7z m264 -10 c-20 -4 -49 -4 -65 1 -23 7 -16 8 36 8 58 0 61 -1 29 -9z
m1046 -959 c18 -8 17 -9 -7 -9 -15 -1 -30 4 -33 9 -7 12 12 12 40 0z m68 -17
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m520 0 c-7 -2 -21 -2
-30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-430 -10 c-13 -2 -33 -2 -45 0 -13
2 -3 4 22 4 25 0 35 -2 23 -4z m202 1 c13 -5 14 -8 3 -15 -16 -11 -60 -11 -85
-2 -14 6 -8 9 22 14 22 3 41 7 42 8 1 0 9 -2 18 -5z m174 2 c-2 -2 -31 -6 -64
-9 -37 -3 -51 -2 -35 3 25 8 107 13 99 6z m-291 -13 c-10 -2 -28 -2 -40 0 -13
2 -5 4 17 4 22 1 32 -1 23 -4z"/>
<path d="M3850 1751 c0 -5 -6 -16 -13 -23 -7 -7 -28 -38 -46 -68 -55 -90 -150
-213 -268 -347 -30 -34 -54 -64 -54 -67 0 -3 -16 -23 -37 -43 -21 -21 -24 -26
-7 -10 17 15 36 27 43 27 7 0 12 7 12 15 0 8 7 15 15 15 8 0 15 5 15 11 0 6
12 23 28 38 15 16 43 48 62 71 19 24 40 48 45 54 6 6 24 29 40 51 17 22 32 42
35 45 4 3 22 28 41 55 18 28 36 52 39 55 3 3 19 26 35 52 17 27 33 48 37 48 8
0 -28 -70 -41 -80 -3 -3 -28 -39 -56 -80 -51 -77 -140 -197 -159 -215 -6 -5
-27 -30 -47 -55 -63 -78 -250 -260 -364 -355 -135 -111 -191 -155 -199 -155
-3 0 -28 -16 -55 -35 -26 -19 -52 -35 -55 -35 -4 0 -26 -13 -49 -29 -23 -15
-67 -41 -97 -57 -30 -15 -57 -31 -60 -34 -3 -3 -36 -19 -75 -34 -38 -16 -90
-38 -115 -50 -25 -11 -47 -21 -50 -21 -3 -1 -12 -5 -20 -8 -21 -10 -182 -58
-200 -61 -8 -1 -33 -7 -55 -14 -94 -27 -200 -43 -351 -53 -89 -6 -170 -9 -180
-6 -11 3 1 6 26 7 77 3 224 17 250 23 14 4 50 10 80 16 80 14 210 47 250 65
47 21 30 19 -63 -4 -334 -84 -692 -83 -992 4 -82 24 -231 81 -250 95 -5 5 -27
14 -48 20 -20 7 -37 16 -37 19 0 4 -15 14 -32 21 -18 8 -58 31 -88 50 -62 41
-54 35 -139 101 -35 28 -66 50 -68 50 -8 0 38 -71 86 -135 58 -75 249 -272
298 -305 20 -14 41 -30 47 -35 6 -6 34 -25 61 -42 28 -18 55 -36 61 -40 56
-42 224 -120 340 -159 97 -32 142 -43 309 -76 79 -15 498 -15 595 0 189 30
335 62 470 106 90 28 246 87 295 110 224 105 386 191 393 208 2 5 8 8 13 8 10
0 87 48 107 67 7 7 17 13 22 13 8 0 194 135 235 171 11 9 43 36 70 60 157 133
312 308 493 554 65 88 210 403 197 425 -7 12 -775 13 -775 1z m197 -28 c-20
-2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z m-2612 -1343 l20 -9 -20 0
c-11 0 -29 4 -40 9 l-20 9 20 0 c11 0 29 -4 40 -9z m143 -17 c-15 -2 -42 -2
-60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z"/>
<path d="M3320 1099 l-35 -31 32 22 c29 19 50 40 41 40 -2 0 -19 -14 -38 -31z"/>
<path d="M2300 490 c-12 -7 -10 -9 7 -7 12 0 19 5 17 9 -6 10 -6 10 -24 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
